import React from "react";
import Layout from "../../components/Layout";

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Dziękujemy za wysłanie wiadomości!</h1>
          <p>Odezwiemy się do Ciebie tak szybko jak to będzie możliwe.</p>
        </div>
      </div>
    </section>
  </Layout>
);
